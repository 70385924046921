import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-newversionavailable',
  templateUrl: './newversionavailable.component.html',
  styleUrls: ['./newversionavailable.component.scss']
})
export class NewversionavailableComponent implements OnInit {
  showBanner = true;

  constructor(
    public applicationInfoService: ApplicationInfoService
  ) { }

  ngOnInit(): void {
    this.startNewVersionTimer();
  }

  reloadPage() {
    window.location.reload();
  }

  startNewVersionTimer() {
    const applicationCheckTimer = timer(5000, 12000);
    applicationCheckTimer.subscribe(() => {
      this.applicationInfoService.checkCurrentUIVersion();
    });
  }
}
