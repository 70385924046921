<div class="controlSettings__wrapper">
    <div class="everleadShadowBox">
        <div id="controlSettingsGeneral" class="controlSettingsGeneral controlSettingsPartial controlSettingsPartial__left" *ngIf="onlyAccess == false">
            <div class="controlSettingsHeader">{{ 'UIControlSettings.Label.General' | texttransform }}</div>
            <div class="controlSettingsLine" *ngIf="currentControlTypeNotSupported == false">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.Type' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <select [(ngModel)]="control.controlType" class="maxWidth" (change)="getControlSpecificSettings()"
                        [disabled]="fixedTypeId != null">
                        <option *ngFor="let controlType of controlTypesFinal" [ngValue]="controlType.id">
                            {{ getContentTypeString(controlType.value) | texttransform }}
                        </option>
                    </select>
                </div>
            </div>   

            <div class="controlSettingsLine">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.DisplayText' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <input [(ngModel)]="control.displayText" type="text" (change)="saveControl()">
                </div>
            </div>   
            <div class="controlSettingsLine">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.Width' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <input [(ngModel)]="control.width" type="text" (change)="saveControl()">
                </div>
            </div>   
            <div class="controlSettingsLine">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.Height' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <input [(ngModel)]="control.height" type="text" (change)="saveControl()">
                </div>
            </div>                   
            <div class="controlSettingsLine">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.ControlSettingGeneralDarkMode' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <input [(ngModel)]="controlSettings['controlSettingGeneralDarkMode']" type="checkbox" (change)="saveControl()">
                </div>
            </div>          
        </div>
        <div class="controlSettingsPartial">
            <div class="controlSettingsHeader" *ngIf="onlyAccess == false">{{ 'UIControlSettings.Label.SpecificSettings' | texttransform }}</div>
    
            <!-- Dropdown special -->
            <div *ngIf="control.controlType == 6"> 
                <div class="controlSettingsLine">
                    <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.AlternateLookupTable' | texttransform }}</label>
                    <div class="controlSettingsLine__value">
                        <select [(ngModel)]="controlSettings['dropdownAlternateLookupTable']" class="maxWidth" (change)="saveControl()">
                            <option [ngValue]=null></option>
                            <option *ngFor="let lookupTable of lookupTables" [ngValue]="lookupTable.id">{{ lookupTable.defaultName }}</option>
                        </select>
                    </div>
                </div>   
            </div>
    
            <!-- Leadstate control spezial -->
            <div *ngIf="control.controlType == 55">
                <div class="controlSettingsLine">
                    <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.LeadstateControlTarget' | texttransform }}</label>
                    <div class="controlSettingsLine__value">
                        <select [(ngModel)]="controlSettings['leadstateControlTarget']" class="maxWidth" (change)="saveControl()">
                            <option ngValue="account">{{ 'Account.Label.Header' | texttransform }}</option>
                            <option ngValue="contact">{{ 'Contact.Label.Header' | texttransform }}</option>
                         </select>
                    </div>
                </div>
            </div>
    
            <!-- EntityFileUploadSpezial -->
            <div *ngIf="control.controlType == 56">
                <div class="controlSettingsLine">
                    <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.EntityFileUploadTarget' | texttransform }}</label>
                    <div class="controlSettingsLine__value">
                        <select [(ngModel)]="controlSettings['entityFileUploadEntity']" class="maxWidth" (change)="saveControl()">
                            <option ngValue="1">{{ 'Account.Label.Header' | texttransform }}</option>
                            <option ngValue="4">{{ 'Contact.Label.Header' | texttransform }}</option>
                         </select>
                    </div>
                </div>
                <div *ngIf="commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['entityFileUploadSourceId'])">
                    <br>
                    <div class="everleadCoral">{{ 'UIControlSettings.Label.EntityFileUploadEnvironmentMissing' | texttransform }}</div>                
                    <div>{{ 'EntityFileUpload.Label.NoEnvironmentContactPM' | texttransform }}</div>
                    <button (click)="generateEntityFileUploadEnvironment()" class="everleadCustomButton">
                        {{ 'UIControlSettings.Label.EntityFileUploadCreateEnvironment' | texttransform }}
                    </button>
                </div>
            </div>
            
            <!-- Register spezial -->
            <div *ngIf="control.controlType == 12 || control.controlType == 43"> 
                <div class="explanationText" *ngIf="control.controlType == 12">{{ 'UIControlSettings.Label.RegisterDescription' | texttransform }}</div>
                <div cdkDropList [cdkDropListData]="control.childs" (cdkDropListDropped)="moveInList($event)">
                    <div *ngFor="let child of control.childs" cdkDrag [cdkDragData]="item">
                        <div class="registerChild">
                            <div class="registerChild__active">
                                <input cdkDragDisabled type="checkbox" [(ngModel)]="child.isActive" (change)="saveSubControl(child)">
                            </div>
                            <div class="registerChild__name" (click)="$event.stopPropagation()">
                                <input [(ngModel)]="child.displayText" class="maxWidth" (change)="saveSubControl(child)">
                            </div>
                            <div class="registerChild__buttons">
                                <i class="fas fa-edit fingerCursor" (click)="editSubControl(child, 0, true)"></i>
                                <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteSubControl(child)"></i>
                                <i class="fas fa-grip-lines fingerCursor" cdkDragHandle></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="registerChild__new" (click)="newRegisterChild()">
                    <i class="fas fa-plus"></i>
                    {{ 'UIControlSettings.Label.AddRegister' | texttransform }}
                </div>
            </div>
    
            <!-- From database -->
            <div *ngFor="let controlSpecificSetting of controlSpecificSettings">
                <div class="controlSettingsLine" *ngIf="onlyAccess == false">
                    <label class="controlSettingsLine__label">{{ controlSpecificSetting.defaultName | texttransform }}</label>
                    <div class="controlSettingsLine__value">
                        <input [(ngModel)]="controlSettings[controlSpecificSetting.setting]" type="checkbox" 
                            (change)="saveControl()" *ngIf="controlSpecificSetting.type== 'checkbox'">
                        <input [(ngModel)]="controlSettings[controlSpecificSetting.setting]" type="text" 
                            (change)="saveControl()" *ngIf="controlSpecificSetting.type== 'text'">
                        <input [(ngModel)]="controlSettings[controlSpecificSetting.setting]" type="number" 
                            (change)="saveControl()" *ngIf="controlSpecificSetting.type== 'number'">
                    </div>
                </div>  
            </div>
        </div> 
    </div>





    <!-- Übersetzungen -->
    <div class="everleadShadowBox">
        <div class="controlSettingsHeader" *ngIf="onlyAccess == false">{{ 'UIControlSettings.Label.Translations' | texttransform }}</div>
        <div class="explanationText">{{ 'UIControlSettings.Label.TranslationsDescription' | texttransform }}</div>
        <div class="translations" *ngIf="onlyAccess == false">
            <div *ngFor="let language of languages">
                <div class="languageTranslation">
                    <div class="languageTranslation__label">{{ language.defaultName }}</div>
                    <div class="languageTranslation__value">
                        <input [(ngModel)]="controlSettings['translation' + language.id]" type="text" (change)="saveControl()">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Access and visibility -->
    <div class="everleadShadowBox">
        <div class="controlSettingsHeader">{{ 'UIControlSettings.Label.Access' | texttransform }}</div>
        <div class="explanationText">{{ 'UIControlSettings.Label.AccessDescription' | texttransform }}</div>
        <div class="controlSettingsLine">
            <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.Invisible' | texttransform }}</label>
            <div class="controlSettingsLine__value">
                <input [(ngModel)]="controlSettings['invisible']" type="checkbox" (change)="saveControl()">
            </div>
        </div>          
        <div class="controlSettingsLine">
            <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.Disabled' | texttransform }}</label>
            <div class="controlSettingsLine__value">
                <input [(ngModel)]="controlSettings['disabled']" type="checkbox" (change)="saveControl()">
            </div>
        </div>
        <div class="controlSettingsLine">
            <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.OnlyVisibleIfAccountIsAvailable' | texttransform }}</label>
            <div class="controlSettingsLine__value">
                <input [(ngModel)]="controlSettings['onlyVisibleIfAccountIsAvailable']" type="checkbox" (change)="saveControl()">
            </div>
        </div>          
        <div class="controlSettingsLine">
            <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.OnlyVisibleIfContactIsAvailable' | texttransform }}</label>
            <div class="controlSettingsLine__value">
                <input [(ngModel)]="controlSettings['onlyVisibleIfContactIsAvailable']" type="checkbox" (change)="saveControl()">
            </div>
        </div>                    
        <div id="controlSettingsAccess" class="controlSettingsGeneral">
            <div class="controlSettingsLine">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.OnlyForSupervisor' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <input [(ngModel)]="controlSettings['onlyForSupervisor']" type="checkbox" (change)="saveControl()">
                </div>
            </div>    
            <div class="controlSettingsLine" *ngIf="applicationInfoService.isDeveloper">
                <label class="controlSettingsLine__label">{{ 'UIControlSettings.Label.OnlyForAdmin' | texttransform }}</label>
                <div class="controlSettingsLine__value">
                    <input [(ngModel)]="controlSettings['onlyForAdmin']" type="checkbox" (change)="saveControl()">
                </div>
            </div>    
        </div>        
    </div>

       
</div>