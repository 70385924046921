import { Component, OnInit } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { AwsCognitoService } from 'app/cognito/aws-cognito.service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { ApplicationinitService } from 'app/jollyjupiter/service/applicationinit.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'environments/environment';
import * as AWS from 'aws-sdk';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { ApolloMethod, GraphQLService } from 'app/shared/service/graphql.service';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

@Component({
  selector: 'app-awslogin',
  templateUrl: './awslogin.component.html',
  styleUrls: ['./awslogin.component.scss']
})
export class AwsloginComponent implements OnInit {
  isInternalUser = false;
  // internalUserName = '';
  userChecked = false;
  pageMode = 1;

  cognitoClientSecret = environment.cognitoClientSecret;
  cognitoClientId = environment.cognitoClientId;  
  cognitoClientPool = environment.cognitoClientPool;
  
  cognitoConsentClientSecret = environment.cognitoConsentClientSecret;
  cognitoConsentClientId = environment.cognitoConsentClientId;
  cognitoConsentUsername = environment.cognitoConsentUsername;
  cognitoConsentEMail = environment.cognitoConsentEMail;
  cognitoConsentPassword = environment.cognitoConsentPassword;

  cognitoIdentity;
  awstoken = null;

  constructor(
    private messagingService: MessagingService,
    private awsCognitoService: AwsCognitoService,
    private applicationinitService: ApplicationinitService,
    private commonService: CommonService,
    public applicationInfoService: ApplicationInfoService,
    private graphQLService: GraphQLService,
    private http: HttpClient
  ) {
    this.cognitoIdentity = new AWS.CognitoIdentityServiceProvider({region: 'eu-central-1'});
   }

  ngOnInit(): void {
    const awsUserName = localStorage.getItem('awsUserName');
    if (!this.commonService.isNullOrUndefined(awsUserName)) {
      this.applicationInfoService.awsUserName = awsUserName;
      this.checkUserName();
    }
    this.getIp().subscribe(getIpResult => {
      // Default A&G check
      if (
        this.commonService.checkIfStringContainsString(getIpResult.toString(), environment.externalAGIpMain) ||
        this.commonService.checkIfStringContainsString(getIpResult.toString(), environment.externalAGIpBackup)
      ) {
        this.loginAG();
      }      
    });
    this.signInUser().then(result => {
      localStorage.setItem('awstoken', result);
    });
  }

  loginAG() {
    this.awsCognitoService.showAwsLoginPage();
  }

  loginUser() {
    let query = `query {
      userLoginByEmail(email: "<0>") {
        userName
        email
        awsCognitoPool {
          clientId
          loginUrl
          clientIdStaging
          loginUrlStaging
          cognitoPoolId
          defaultName
        }
      }
    }`;
    query = query.replace('<0>', this.applicationInfoService.awsUserName);
    this.graphQLService.apolloGQLpromiseWithParameter('main', ApolloMethod.Query, query, [])
    .then(userInfo => { 
      let userInfoDetails = userInfo.data.userLoginByEmail;
      if (userInfoDetails != null) {
        let userInfoClientId = userInfoDetails.awsCognitoPool.clientIdStaging;
        if (environment.dataStage == 'urlLive' || environment.dataStage == 'urlLiveBeta') {
          userInfoClientId = userInfoDetails.awsCognitoPool.clientId;
        }
        this.login(
          this.applicationInfoService.awsUserName, 
          this.applicationInfoService.awsPassword,  
          userInfoClientId,
          userInfoDetails.awsCognitoPool.cognitoPoolId
        );  
      } else {
        this.login(
          this.applicationInfoService.awsUserName, 
          this.applicationInfoService.awsPassword, 
          this.cognitoClientId,
          this.cognitoClientPool
        );  
      }
    });
  }

  login(userName, password, clientId, userPoolId){
    let authenticationDetails = new AuthenticationDetails({
      Username: userName,
      Password: password,
    });
    let poolData = {
      UserPoolId: userPoolId,
      ClientId: clientId
    };

    let userPool = new CognitoUserPool(poolData);
    let userData = { Username: userName, Pool: userPool };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (awsLoginResult) => {
          localStorage.setItem('awstoken', awsLoginResult.getAccessToken().getJwtToken())
          localStorage.setItem('awsrefreshtoken', awsLoginResult.getRefreshToken().getToken())
          this.awsCognitoService.silentRenew().subscribe(result => {
            this.applicationInfoService.showAWsLoginPage = false;
            this.awsCognitoService.setTokenDetails(result, true);           
            this.applicationinitService.startApplication();           
          }, error => { 
            this.awsCognitoService.handleLoginError(); 
            console.warn(error);
          });    
    }, onFailure: (err) => {
      console.warn(err);
      cognitoUser.changePassword('nVGrI9$c', 'nVGrI9$c.', null);
      this.messagingService.showDefaultInfo('Login', err.message);      
    }});
  }

  checkUserName() {    
    if (!this.commonService.isNullOrUndefined(this.applicationInfoService.awsUserName)) {
      if (this.applicationInfoService.awsUserName == '') {        
        localStorage.removeItem('awsUserName');
        this.userChecked = false;
        return;
      }
      localStorage.setItem('awsUserName', this.applicationInfoService.awsUserName);
      if (this.commonService.checkIfStringContainsString(this.applicationInfoService.awsUserName, '@')) {
        const userDetails = this.applicationInfoService.awsUserName.split('@');
        if (this.commonService.checkIfStringContainsString(userDetails[1], 'alex-gross.com')) {
          this.isInternalUser = true;
          this.pageMode = 1;
        } else {
          this.isInternalUser = false;
        }
      } else {
        this.isInternalUser = false;
      }
    }
    this.userChecked = true;
  }

  nextPage() {
    if (this.isInternalUser) {
      this.loginAG();
    } else {
      this.pageMode = 2;
    }    
  }

  openPasswordReset() {
    this.commonService.openUrl(environment.cognitoForgotPasswordURL, '_new');
  }

  public async signInUser(): Promise<any> {
    const params = {
      AuthFlow: "USER_PASSWORD_AUTH",
      ClientId: this.cognitoConsentClientId,
      AuthParameters: {
      "USERNAME": this.cognitoConsentUsername,
      "PASSWORD": this.cognitoConsentPassword,
      "SECRET_HASH": this.generateHash(),
      }
    }
  
    try{
      const data = await
      this.cognitoIdentity.initiateAuth(params).promise();
      this.awstoken = data["AuthenticationResult"]["AccessToken"];      
      return this.awstoken;
    }
    catch (error) {
      console.log(error);
      return null;
    }
  }
  
  private generateHash(): string{
    const key = CryptoJS.enc.Utf8.parse(this.cognitoConsentClientSecret)
    const userInfo = CryptoJS.enc.Utf8.parse(this.cognitoConsentUsername + this.cognitoConsentClientId)
    const hmac = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(userInfo, key))
    return hmac    
  }

  getIp(): Observable<string> {
    return this.http.get(environment.externalIPCheckLink, {responseType: 'text'})
  }
}
