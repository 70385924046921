<div [ngClass]="{ darkMode: darkMode }">
    <div class="entityFileUpload__header" [ngClass]="{ darkMode: darkMode }">
        <div class="entityFileUpload__header__text">
            <!-- <i class="fas fa-plus fingerCursor" (click)="showUpload = !showUpload"></i> -->
            {{ commonService.getTranslationValueFromArray(this.controlDefinition, 'displayText') | texttransform }}
        </div>
        <div class="uploadEntity" *ngIf="showEntitySelection">
            <div class="uploadEntity__line" *ngIf="applicationInfoService.currentAccount?.isDummyAccount != true">
                <input type="radio" [(ngModel)]="entityIdSelection" [value]=1 (change)="updateUploadEntityValue()">
                {{ 'Account.Label.Header' | texttransform }}
            </div>
            <div class="uploadEntity__line" *ngIf="applicationInfoService.currentContact != null">
                <input type="radio" [(ngModel)]="entityIdSelection" [value]=4 (change)="updateUploadEntityValue()">
                {{ 'Contact.Label.Header' | texttransform }}
            </div>
        </div>
    </div>
    <div class="entityFileUpload__wrapper" *ngIf="checkVisibility()" [ngClass]="{ darkMode: darkMode }">
        <div *ngIf="entityFiles.length == 0 && showFileList">
            {{ 'EntityFileUpload.Label.NoFilesAvailable' | texttransform }}
        </div>
        <div class="entityFileUpload__fileListe" *ngIf="showFileList">
            <div class="entityFileUploadFileListItem" *ngFor="let entityFile of entityFiles">
                <div class="entityFileUploadFileListItem__name">{{ entityFile.name }}</div>
                <!-- <div class="entityFileUploadFileListItem__size">{{ entityFile.size }}</div> -->
                <div class="entityFileUploadFileListItem__buttons">
                    <i class="fas fa-download fingerCursor" (click)="downloadEntityFiles(entityFile)"></i>
                    <i class="fas fa-trash everleadCoral fingerCursor" (click)="deleteEntityFiles(entityFile)"></i>
                </div>
            </div>
        </div>
        <div class="entityFileUpload__fileUpload dragDrop" *ngIf="showUpload">
            <app-externalfiledragcontrol (selectedFiles)="onFilesSelected($event)" [darkMode]="darkMode"></app-externalfiledragcontrol>     
        </div>
        <div class="entityFileUploadOverwrite" *ngIf="showUpload">
            <input [(ngModel)]="overwrite" class="entityFileUploadOverwrite__input" type="checkbox">
            <div class="entityFileUploadOverwrite__label">{{ 'EntityFileUpload.Label.OverwriteFiles' | texttransform }}</div>
        </div>
    </div>
    <div *ngIf="entityId == 4 && applicationInfoService.currentContact == null" class="entityFileUploadNoContact">
        <i class="fas fa-info-circle"></i>
        <label>{{ 'EntityFileUpload.Label.NoContactSelected' | texttransform }}</label>
    </div>
    <div *ngIf="commonService.isNullOrUndefined(this.applicationInfoService.applicationSettings['entityFileUploadSourceId'])" 
        class="entityFileUploadEnvironment" [ngClass]="{ darkMode: darkMode }">
        {{ 'EntityFileUpload.Label.NoEnvironmentContactPM' | texttransform }}
    </div>
</div>

<div [ngClass]="{ hideInDom: showFiluUploadPopup==false }" class="entityFileUploadPopupBackground" [id]="getEntityFileUploadPopupId('Background')"></div>
<div [ngClass]="{ hideInDom: showFiluUploadPopup==false }" class="entityFileUploadPopup" [id]="getEntityFileUploadPopupId()">
    <div class="entityFileUploadPopup__header">
        <div class="entityFileUploadPopup__file entityFileUploadPopup__file">
            <div class="entityFileUploadPopup__file__label">{{ 'EntityFileUpload.Label.Name' | texttransform }}</div>
            <div class="entityFileUploadPopup__file__select">{{ 'EntityFileUpload.Label.Type' | texttransform }}</div>
            <div class="entityFileUploadPopup__file__input">{{ 'EntityFileUpload.Label.Comment' | texttransform }}</div>
        </div>
    </div>
    <div class="entityFileUploadPopup__files">
        <div *ngFor="let fileToUpload of filesToUpload" class="entityFileUploadPopup__file">
            <label class="entityFileUploadPopup__file__label">{{ fileToUpload.name }}</label>
            <select [(ngModel)]="fileToUpload.type" class="entityFileUploadPopup__file__select">            
                <option *ngFor="let entityfileUploadContentType of entityfileUploadContentTypes" [ngValue]="entityfileUploadContentType.id">
                    {{ entityfileUploadContentType.defaultName }}
                </option>
            </select>
            <input [(ngModel)]="fileToUpload.comment" class="entityFileUploadPopup__file__input">
        </div>    
    </div>
    <div class="entityFileUploadPopup__buttons">
        <button class="everleadCustomButton" (click)="uploadFiles()">{{ 'EntityFileUpload.Label.UploadFiles' | texttransform }}</button>
        <button class="everleadCustomButton" (click)="showFiluUploadPopup = false">{{ 'General._.Cancel' | texttransform }}</button>
    </div>
</div>