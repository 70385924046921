// ng serve

// Build everlead no href
// node --max_old_space_size=8192 ./node_modules/@angular/cli/bin/ng build --configuration=production --output-path=dist/everlead --output-hashing=all

export const environment = {
  applicationName: window['env']['applicationName'] || 'JollyJupiter',

  production: true,
  isDebug: true,

  testEnvironment: window['env']['testEnvironment'] || '',

  /*************** API Settings ****************/
  useCognito: window['env']['useCognito'] || false,
  trApiUrl: window['env']['trApiUrl'] || '',


  // LOCAL
  // apiUrl: window['env']['apiUrl'] || 'http://localhost:5000/api',
  // graphQlUrl: window['env']['graphQlUrl'] || 'http://localhost:5000/graphql/',
  // dataStage: window['env']['dataStage'] || 'urlDev',

  // STAGING
  apiUrl: window['env']['apiUrl'] || 'http://staging.everlead-app.com/central/api',
  graphQlUrl: window['env']['graphQlUrl'] || 'http://staging.everlead-app.com/central/graphql/',
  dataStage: window['env']['dataStage'] || 'urlDevBeta',
  externalAGIpMain: window['env']['externalAGIpMain'] || '',
  externalAGIpBackup: window['env']['externalAGIpBackup'] || '',
  externalIPCheckLink: window['env']['externalIPCheckLink'] || '',
  apiData: window['env']['apiData'] || 'https://staging.everlead-app.com/data/axivas/api',
  apiBackend: window['env']['apiBackend'] || 'https://staging.everlead-app.com/backend/api',
  apiMail: window['env']['apiMail'] || 'https://staging.everlead-app.com/mail/api',
  logApiUrl: window['env']['logApiUrl'] || 'http://deswdocker004:7000/api/logger',
  translationAPI: window['env']['translationAPI'] || 'http://staging.everlead-app.com/central/api',
  signalRUrl: window['env']['signalRUrl'] || '',
  signalRUrlMiddleware: window['env']['signalRUrlMiddleware'] || 'https://services.axivas.com/SoftphoneAPI/Softphone',
  newSoftPhone: window['env']['newSoftPhone'] || 'false',
  trialexpiredpage: window['env']['trialexpiredpage'] || '',
  portainer: window['env']['portainer'] || 'https://staging.everlead-app.com/portainer/#/home',
  hasuraCentral: window['env']['hasuraCentral'] || 'https://backend-jj.axivas.com/hasura/console/',
  hasuraData:  window['env']['hasuraData'] || 'https://staging.everlead-app.com/hasura/axivas/console/',
  playgroundCentral: window['env']['playgroundCentral'] || 'https://staging.everlead-app.com/central/ui/playground',
  playgroundData: window['env']['playgroundData'] || 'https://staging.everlead-app.com/backend/ui/playground',
  publishTemplate: window['env']['publishTemplate'] || '',
  renewalThankYouEN: window['env']['renewalThankYouEN'] || 'https://everlead.staging360.net/en/thank-you-renewal/',
  renewalThankYouDE: window['env']['renewalThankYouDE'] || 'https://everlead.staging360.net/thank-you-verlaengerung/',
  renewalFailedEN: window['env']['renewalFailedEN'] || 'https://everlead.staging360.net/en/renewal-failed/',
  renewalFailedDE: window['env']['renewalThankYouDE'] || 'https://everlead.staging360.net/verlaendergung-fehlgeschlagen/',
  renewalOopsEN: window['env']['renewalOopsEN'] || 'https://www.everlead.ai/en/trial-renewal-impossible/',
  renewalOopsDE: window['env']['renewalOopsDE'] || 'https://www.everlead.ai/trial-verlaengerung-nicht-moeglich/',


  /*************** Auth Settings ****************/
  authorityServerUrl: window['env']['authorityServerUrl'] || 'https://auth.alex-gross.com',
  // serverUrl: 'https://everlead.de/',
  // baseRef: '',
  // clientId: 'Everlead',

  // serverUrl: 'http://localhost:4200/',
  // baseRef: 'JollyJupiter',
  // clientId: 'JJClientDev',

  /*************** Cognito ****************/
  cognitoSilentRenewRefreshTime: window['env']['cognitoSilentRenewRefreshTime'] || '',
  cognitoClientId: window['env']['cognitoClientId'] || '',
  cognitoClientSecret: window['env']['cognitoClientSecret'] || '',
  cognitoClientPool: window['env']['cognitoClientPool'] || '',
  cognitoLoginURL: window['env']['cognitoLoginURL'] || '',
  cognitoRedirectURL: window['env']['cognitoRedirectURL'] || '',
  cognitoForgotPasswordURL: window['env']['cognitoForgotPasswordURL'] || '',
  cognitoTokenURL: window['env']['cognitoTokenURL'] || '',
  cognitoLogout: window['env']['cognitoLogout'] || '',

  cognitoConsentUsername: window['env']['cognitoConsentUsername'] || '',
  cognitoConsentEMail: window['env']['cognitoConsentEMail'] || '',
  cognitoConsentPassword: window['env']['cognitoConsentPassword'] || '',
  cognitoConsentClientSecret: window['env']['cognitoConsentClientSecret'] || '',
  cognitoConsentClientId: window['env']['cognitoConsentClientId'] || '',

  /*************** BoldBi ****************/
  boldBiApiHost: window['env']['boldBiApiHost'] || '',
  boldBiRootUrl: window['env']['boldBiRootUrl'] || '',
  boldBiEnvironment: window['env']['boldBiEnvironment'] || '',
  boldBiSiteIdentifier: window['env']['boldBiSiteIdentifier'] || '',
  boldBiSiteAuthorizationUrl: window['env']['boldBiSiteAuthorizationUrl'] || '',
  boldBiGetDashboardsUrl: window['env']['boldBiGetDashboardsUrl'] || '',
  boldBiBaseUrl: window['env']['boldBiBaseUrl'] || '',
  boldBiDashboardServerApiUrl: window['env']['boldBiDashboardServerApiUrl'] || '',

  /*************** RingCentral ****************/
  useRingCentral: window['env']['useRingCentral'] || '',
  ringCentralApiRul: window['env']['ringCentralApiRul'] || '',
  ringCentralAuthRedirect: window['env']['ringCentralAuthRedirect'] || '',
  ringCentralAuthRedirectClean: window['env']['ringCentralAuthRedirectClean'] || '',
  ringCentralWebRTCAuthRedirectClean: window['env']['ringCentralWebRTCAuthRedirectClean'] || '',

  /*************** WebPush ****************/
  webPushUrl: window['env']['webPushUrl'] || '',

  /*************** Consent ****************/
  consentPageUrl: window['env']['consentPageUrl'] || 'https://consent.staging.everlead-app.com/consent',
  consentPagePreviewUrl: window['env']['consentPagePreviewUrl'] || 'https://consent.staging.everlead-app.com/consent?pg=<0>&debug=1',

  serverUrl: window['env']['serverUrl'] || '',
  baseRef: window['env']['baseRef'] || '',
  clientId: window['env']['clientId'] || ''
  };
