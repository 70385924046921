<div class="wizardSubheader__wrapper">
    <div class="wizardSubheader">
        <label class="wizardSubheader__headerLabel">{{ wizardService.pages[wizardService.wizardPage - 1].name |
            texttransform }}</label>
        <label class="wizardSubheader__descriptionLabel">{{ wizardService.pages[wizardService.wizardPage - 1].desc |
            texttransform }}</label>
    </div>

    <div class="wizardPageContent maxHeight">
        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 1" class="maxHeight">
            <div class="everleadSmallHeader">{{ 'MicroCampaign.Label.DefaultName' | texttransform }}
                <app-setting-required></app-setting-required>
            </div>
            <div class="wizardDetailItem">
                <input [(ngModel)]="wizardService.wizardArray.batchName" type="text" class="maxWidth" (change)="updateMailshotBatch()">
            </div>
            <div class="everleadSmallHeader">
                {{ 'Task.Label.Campaign' | texttransform }}
                <app-setting-required></app-setting-required>
            </div>
            <div class="wizardDetailItem">
                <select [(ngModel)]="wizardService.wizardArray.campaignId" class="maxWidth" (change)="updateMailshotBatch()"
                    [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == 'true'">
                    <option *ngFor="let campaign of campaigns" [ngValue]="campaign.id">{{ campaign.defaultName }}
                    </option>
                </select>
            </div>
            <div class="everleadSmallHeader">
                {{ 'MicroCampaign.Label.MicroCampaignId' | texttransform }}
                <app-setting-required></app-setting-required>
            </div>
            <div class="wizardDetailItem">
                <select [(ngModel)]="wizardService.wizardArray.resultId" class="maxWidth" (change)="updateMailshotBatch()">
                    <option *ngFor="let microcampaign of microcampaigns" [ngValue]="microcampaign.id">{{ microcampaign.defaultName }}
                    </option>
                </select>
            </div>            
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 2" class="maxHeight">
            <div class="wizardDetailItem everleadShadowBox2" *ngIf="wizardService.wizardArray.consentTypes.length > 0">
                <label class="microCampaignWizardConsentHeader">{{ 'MicroCampaign.Label.ConsentString' | texttransform }}</label>
                <label class="microCampaignWizardConsentInfo everleadCoral" [innerHTML]="consentString"></label> 
                <label class="microCampaignWizardConsentInfo" [innerHTML]="mediaString"></label>
            </div>

            <div class="myLeadsFilterHeader__wrapper">
                <div *ngIf="applicationInfoService.myLeadsSelectedFilter != null" class="myLeadsFilterHeader">
                    <i class="fas fa-filter"></i>
                    <label>{{ applicationInfoService.myLeadsSelectedFilter.defaultName }}</label>
                    <i class="fas fa-trash fingerCursor" (click)="clearDataFilter();$event.stopPropagation()"></i>
                    <i class="fas fingerCursor" (click)="filterExpanded = !filterExpanded" [ngClass]="{
                'fa-chevron-down': !filterExpanded,
                'fa-chevron-up': filterExpanded
              }"></i>
                </div>
    
                <div *ngIf="applicationInfoService.myLeadsSelectedFilter == null" class="myLeadsFilterHeader">
                    <i class="fas fa-filter"></i>
                    <label class="fingerCursor">{{ 'MyLeads.Label.NoFilterSelected' | texttransform }}</label>
                    <i class="fas fa-edit customTextControlWrapper__button fingerCursor"
                        (click)="editDataFilter();$event.stopPropagation()"></i>
                </div>
            </div>            
            <div>
                <div *ngIf="filterExpanded">
                    <div *ngFor="let filterSegment of applicationInfoService?.myLeadsSelectedFilter?.filterSegments; let i = index">
                        <div *ngIf="i > 0" class="everleadShadowBox dataFilter__mainPanel__criteriaGroupSpacer">AND</div>
                        <div>
                            <app-datafilter-groupitem [filterSegment]="filterSegment" [filter]="selectedFilter"
                                [filterSegmentOperators]="filterSegmentOperators" [memberEntities]="validEntities"
                                disabled=true [operators]="filterSegmentMemberOperators"></app-datafilter-groupitem>
                        </div>
                    </div>
                </div>
            </div>
            <app-custom-universal controlid=217843></app-custom-universal>
        </div>

        <div *ngIf="wizardService.pages[wizardService.wizardPage - 1]?.pageId == 3" class="maxHeight">
            <div class="microCampaignWizardFinalize">
                <label class="bookingEntityMemberLabel">{{ 'Task.Label.TaskOwner' | texttransform }}</label>
                <select [(ngModel)]="wizardService.wizardArray.followUpUserId" class="bookingDropdown maxWidth" (change)="updateMailshotBatch()"
                    [disabled]="applicationInfoService.applicationSettings['isDemoproject'] == true">
                    <option *ngFor="let user of users" [ngValue]="user.id">
                        {{ user.userName }}
                    </option>
                </select>
            </div>
            <div class="microCampaignWizardFinalize">
                <label class="bookingEntityMemberLabel">{{ 'MicroCampaign.Label.RunDate' | texttransform }}</label>
                <input [(ngModel)]="wizardService.wizardArray.followUpDate" [owlDateTimeTrigger]="dt8"
                    [owlDateTime]="dt8" readonly class="bookingInput" aria-modal="true" (dateTimeChange)="updateMailshotBatch()">
            </div>
            <owl-date-time #dt8></owl-date-time>
            <div class="microCampaignWizardFinalize">
                <button class="everleadCustomButton" (click)="startMicroCampaignBatch()"
                    [disabled]="
                        wizardService.wizardArray.totalCount == 0 ||
                        wizardService.wizardArray.campaignId == null ||
                        wizardService.wizardArray.resultId == null ||
                        wizardService.wizardArray.followUpUserId == null ||
                        wizardService.wizardArray.defaultName == '' 
                    "
                >{{ 'MicroCampaign.Label.StartMicroCampaign' | texttransform }}</button>
            </div>
        </div>
    </div>
</div>