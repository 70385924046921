<div class="contactLeadstateOverviewWrapper" *ngIf="checkContactRequirement()">
    <div class="contactLeadstateOverviewHeader__wrapper">
        <div class="contactLeadstateOverviewHeaderItem">
            <div class="contactLeadstateOverviewMain">{{ 'Account.Label.Leadstate' | texttransform }}</div>
            <div class="contactLeadstateOverviewHeader">
                <!-- <div class="contactLeadstateOverviewHeader__label" [ngClass]="{
                    jjBold: toggled
                }">{{ 'General.Label.CurrentLeadstate' | texttransform }}</div> -->
                <div class="contactLeadstateOverviewHeader__leadstate">{{ currentLeadstateName | texttransform }}</div>
                <div class="contactLeadstateOverviewHeader__toggle" (click)="changeToggle()" class="fingerCursor">
                    <i class="fas fa-chevron-down" *ngIf="toggled == false"></i>
                    <i class="fas fa-chevron-up" *ngIf="toggled == true"></i>
                </div>
            </div>                
        </div>
        <div class="contactLeadstateOverviewHeaderItem">
            <div class="contactLeadstateOverviewMain">{{ 'Account.Label.ProgressState' | texttransform }}</div>
            <div class="contactLeadstateOverviewHeader">
                {{ currentEditingStateName | texttransform }}
            </div>
        </div>
    </div>

    <div *ngIf="loadingData==true" style="height: 22px;">
        <i class="fas fa-spinner fa-spin" style="margin-right: 5px;"></i>{{ 'General.Label.LoadingData' | texttransform }}
    </div>

    <div class="contactLeadstateOverviewCampaignItem 
        contactLeadstateOverviewCampaignItemHeader" *ngIf="contactLeadStatusCampaigns.length > 0 && toggled == true">
        <div class="contactLeadstateOverviewCampaignItem__campaign">
            {{ 'Task.Label.Campaign' | texttransform }}
        </div>
        <div class="contactLeadstateOverviewCampaignItem__leadstate">
            {{ 'Account.Label.Leadstate' | texttransform }}
        </div>
        <div class="contactLeadstateOverviewCampaignItem__subresults" *ngIf="showNotesInSecondLine == false && target == 'contact'">
            {{ 'General.Label.Objections' | texttransform }}
        </div>
        <div class="contactLeadstateOverviewCampaignItem__icon">

        </div>
    </div>

    <div class="contactLeadstateOverviewCampaigns" *ngIf="toggled == true && loadingData==false && contactLeadStatusCampaigns.length == 0">
        {{ 'LeadstateOverview.Label.NoLeadstateChangesYet' | texttransform }}
    </div>

    <div class="contactLeadstateOverviewCampaigns" *ngIf="toggled == true && loadingData==false && contactLeadStatusCampaigns.length > 0">
        <div *ngFor="let contactLeadStatusCampaign of contactLeadStatusCampaigns" class="contactLeadstateOverviewCampaignItem__wrapper fingerCursor"
            (click)="showDetails(contactLeadStatusCampaign)">
            <div class="contactLeadstateOverviewCampaignItem">
                <div class="contactLeadstateOverviewCampaignItem__campaign">
                    {{ getCampaignName(contactLeadStatusCampaign.campaignId) | texttransform }}
                </div>
                <div class="contactLeadstateOverviewCampaignItem__leadstate">
                    {{ getLeadstateName(contactLeadStatusCampaign.leadStatusId) | texttransform }}
                </div>
                <div class="contactLeadstateOverviewCampaignItem__subresults" [tippy]="objections[contactLeadStatusCampaign.campaignId]"
                    *ngIf="showNotesInSecondLine == false && target == 'contact'">
                    {{ objections[contactLeadStatusCampaign.campaignId] }}
                </div>
                <div class="contactLeadstateOverviewCampaignItem__icon" [tippy]="'LeadstateOverview.Label.ShowDetails' | texttransform">
                    <i class="fas fa-info-circle"></i>
                </div>
            </div>          
            <div *ngIf="showNotesInSecondLine == true && target == 'contact'" class="objectionsSecondLine">
                {{ objections[contactLeadStatusCampaign.campaignId] }}
            </div>        
        </div>
    </div>
</div>