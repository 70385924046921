import { Component, OnInit } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { EventService } from 'app/jollyjupiter/service/event.service';

@Component({
  selector: 'app-admin-job-check-result',
  templateUrl: './admin-job-check-result.component.html',
  styleUrls: ['./admin-job-check-result.component.scss']
})
export class AdminJobCheckResultComponent implements OnInit {
  errorObject = null;
  missingParameterFired = false;

  constructor(
    private applicationInfoService: ApplicationInfoService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.errorObject = this.applicationInfoService.miscSettings['jobCheckResult'];
  }

  addMissingParameter() {
    this.eventService.customEvent.emit({
      id: 'addMissingJobStepParameter',
      value: this.errorObject.stepsWithMissingParameter
    });
    this.missingParameterFired = true;
  }
}
