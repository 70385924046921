import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Input } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { DesignerService } from 'app/jollyjupiter/service/admin/designer.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { UiService } from 'app/jollyjupiter/service/ui.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Component({
  selector: 'app-contols-design-settings',
  templateUrl: './contols-design-settings.component.html',
  styleUrls: ['./contols-design-settings.component.scss']
})
export class ContolsDesignSettingsComponent implements OnInit {
  @Input() control = null;
  @Input() onlyAccess = false;
  @Input() fixedTypeId = null;
  lookupTables = [];
  controlSettings = null;
  controlTypes = [];
  controlTypesFinal = [];
  controlSpecificSettings = [];
  languages = [
    { id: 2, defaultName: 'Deutsch' },
    { id: 1, defaultName: 'English' }
  ];  
  currentControlTypeNotSupported = false;

  constructor(
    private commonService: CommonService,
    private uiService: UiService,
    private designerService: DesignerService,
    public applicationInfoService: ApplicationInfoService,
    private eventService: EventService,
    private externaldatasourceService: ExternaldatasourceService,
    private sharedAPI: SharedAPI,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void { 
    if (this.commonService.isNullOrUndefined(this.control.settings)) {
      this.controlSettings = new Object();
    } else {
      this.controlSettings = JSON.parse(this.control.settings);
    }
    this.getControlTypes();
    this.getControlSpecificSettings();
    this.getLookupTables();
    if (this.fixedTypeId != null) {
      this.control.controlType = this.fixedTypeId;
    }
  }

  getControlTypes() {    
    const allowedTypes = this.applicationInfoService.applicationSettings['nonAdminUserControls'].split(';');
    this.controlTypesFinal = [];
    this.controlTypes = this.uiService.getControlTypeArray();
    if (this.applicationInfoService.isDeveloper) {
      this.controlTypesFinal = this.controlTypes;
    } else {
      this.controlTypes.forEach(type => {
        if (this.commonService.checkIfItemIsInArray(allowedTypes, type.id.toString())) {
          this.controlTypesFinal.push(type);
        }
      });
  
      if (!this.commonService.checkIfItemIsInArray(allowedTypes, this.control.controlType.toString())) {
        this.currentControlTypeNotSupported = true;
      }  
    }
  }

  saveControl() {
    this.control.settings = JSON.stringify(this.controlSettings);
    this.designerService.saveControl(this.control)
  }

  nullValue(value) {
    this.controlSettings[value] = null;
    this.saveControl();
  }

  getControlSpecificSettings() {
    this.controlSpecificSettings = [];
    const settingInfo = this.applicationInfoService.applicationSettings['controlSpecificSettings'.concat(this.control.controlType)];
    if (!this.commonService.isNullOrUndefined(settingInfo)) {
      const settingInfoDetails = settingInfo.split(';');
      settingInfoDetails.forEach(setting => {
        const settingDetails = setting.split('|');
        this.controlSpecificSettings.push({
          defaultName: 'UIControlSettings.Label.'.concat(this.commonService.capitalizeFirstLetter(settingDetails[0])),
          setting: settingDetails[0],
          type: settingDetails[1]
        });
      });
    }

    // Init defaultValues
    this.initValueForControlType(55, 'leadstateControlTarget', 'contact');
    this.initValueForControlType(56, 'entityFileUploadEntity', '4');    
    this.saveControl();
  }

  getLookupTables() {
    this.lookupTables = [];        
    this.applicationInfoService.projectLookupTables.forEach(lookupTable => {
      let displayName = 'No definition';
      if (lookupTable.lookups.length > 0) {
        if (lookupTable.lookups[0].definition != null) {
          displayName = this.commonService.getTranslationValueFromArray(lookupTable.lookups[0].definition);
          displayName = this.commonService.capitalizeFirstLetter(displayName);
        }  
      }
      this.lookupTables.push({ 
        id: ''.concat(lookupTable.source, ';', lookupTable.definitionId),
        defaultName: displayName
      });
      this.lookupTables.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);  
    });
  }

  addSubControl() {
    let control = this.designerService.createNewControl();
    control.controlType = 0;
    control.displayText = 'Tab';
    control.parentControlId = this.control.id;
    control.order = this.control.childs.length;
    console.warn(control);
    this.uiService.createControl(control).then(createResult => {
      control.logicalControlId = createResult.id;
      control.id = createResult.id;
      this.applicationInfoService.controls.Add(control.id, control);
      this.control.childs.push(control);    
      this.saveSubControlOrder();
    });    
  }

  newRegisterChild() {
    this.addSubControl();
  }

  saveSubControlOrder() {
    const promiseArray: Promise<any>[] = [];
    let order = 0;
    this.control.childs.forEach(child => {
      child.order = order;
      promiseArray.push(this.uiService.saveControl(child));
      order ++;
    });
    Promise.all(promiseArray).then(() => {
      this.eventService.customEvent.emit({ id: 'updateRegisterControl', value: this.control });
    });
  }

  deleteSubControl(control) {
    this.uiService.deleteSubControl(control).then(() => {
      this.commonService.removeItemFromArray(this.control.childs, control);
    });
  }

  editSubControl(control, fixedTypeId = null, isContainer = false) {
    const controlToEdit = this.applicationInfoService.controls.Item(control.logicalControlId);
    this.applicationInfoService.miscSettings['controlEditLightControl'] = controlToEdit;
    this.applicationInfoService.miscSettings['controlEditLightControlFixedTypeId'] = fixedTypeId;
    if (isContainer == true) {
      this.applicationInfoService.miscSettings['controlEditLightControlMode'] = 2;      
    }  else {
      this.applicationInfoService.miscSettings['controlEditLightControlMode'] = 1;      
    }
    this.eventService.showJjPopup('UIControlSettings.Label.Designer', 'controleditlight', '1400px;800px');    
  }

  saveSubControl(child) {
    this.uiService.saveControl(child);
    if (this.control.controlType == 12) { this.eventService.customEvent.emit({ id: 'updateRegisterControl', value: this.control }); }
  }

  moveInList(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.saveSubControlOrder();
    }
  }

  getContentTypeString(controlType) {
    return 'UIControlSettings.ControlType.'.concat(controlType);
  }

  initValueForControlType(controlType, value, defaultValue) {
    if (this.control.controlType == controlType) {
      if (this.commonService.isNullOrUndefined(this.controlSettings[value])) {
        this.controlSettings[value] = defaultValue;
      }
    }
  }

  generateEntityFileUploadEnvironment() {
    this.loaderService.display(true);
    const libraryObject = {
      projectId: this.applicationInfoService.projectID,
      defaultName: 'Entity Upload Library',
      isActive: true,
      isVisible: false,
      cloudConnectionId: 9,
      maxContentLengthInMb: 150
    };
    this.externaldatasourceService.executeExternalDataSource(806, [
      this.commonService.getModifyArrayBody(libraryObject, [])
    ]).then(getContentLibrariesResult => {
      const contentLibraryItem = {
        projectId: this.applicationInfoService.projectID,
        libraryId: getContentLibrariesResult.id,
        folderName: 'Documents/Everlead/Libraries/'.concat(this.applicationInfoService.projectID.toString(), '/', getContentLibrariesResult.id,'/', this.commonService.createGuid()),
        displayName: 'EntityUploadFolder',
        parentFolderId: null,
      }
  
      this.sharedAPI.contentLibraryCreatFolder(contentLibraryItem).subscribe( newFolderResult => {      
        this.externaldatasourceService.executeExternalDataSource(674,[this.commonService.getModifyArrayBody({
          value: getContentLibrariesResult.id.toString(), setting: 'entityFileUploadSourceId', projectId: this.applicationInfoService.projectID
        }, [])]);
        this.externaldatasourceService.executeExternalDataSource(674,[this.commonService.getModifyArrayBody({
          value: newFolderResult.result.id.toString(), setting: 'entityFileUploadSourceFolderId', projectId: this.applicationInfoService.projectID
        }, [])]);
        this.applicationInfoService.applicationSettings['entityFileUploadSourceId'] = getContentLibrariesResult.id;
        this.applicationInfoService.applicationSettings['entityFileUploadSourceFolderId'] = newFolderResult.result.id;
        this.eventService.customEvent.emit({ id: 'refreshEntityFileUpload' });
        this.loaderService.display(false);
      }, error => { 
        this.loaderService.display(false);
      });
    }).catch(() => {
      this.loaderService.display(false);
    });
  }
}
