import { Injectable } from '@angular/core';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { SharedAPI } from 'app/shared/service/sharedAPI';

@Injectable({
  providedIn: 'root'
})
export class AdminJobsService {
  jobSchedules = [];
  jobStepTypes = [];
  jobs = [];
  jobsOrig = [];

  constructor(
    public commonService: CommonService,
    private loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService,
    private externaldatasourceService: ExternaldatasourceService,
    private eventService: EventService,
    private sharedAPI: SharedAPI
  ) {}

  getSchedules(): Promise<any> {
    return new Promise((getSchedulesResolve, getSchedulesReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(230)
      .then(getSchedulesResult => {
        getSchedulesResult.sort((a, b) => a.defaultName < b.defaultName ? -1 : 1);
        this.jobSchedules = getSchedulesResult;
        getSchedulesResolve(null);
        this.loaderService.display(false);
      })
      .catch(error => {
        console.error(error);
        getSchedulesReject();
        this.loaderService.display(false);
      });
    });
  }

  launchJob(jobId) {
    this.sharedAPI.restartJob(jobId).subscribe(launchJobResult => {
      console.log('launchJob', launchJobResult);
    });
  }

  launchJobStep(stepId) {
    this.sharedAPI.runJobStep(stepId).subscribe(launchJobResult => {
      console.log('launchJobStep', launchJobResult);
    });
  }

  getJobStepTypes(): Promise<any> {
    return new Promise((getJobStepTypesResolve, getJobStepTypesReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(223)
      .then(getJobStepTypesResult => {
        this.jobStepTypes = getJobStepTypesResult;
        getJobStepTypesResolve(null);
        this.loaderService.display(false);
      })
      .catch(error => {
        getJobStepTypesReject();
        this.loaderService.display(false);
      });
    });
  }

  getJobs(): Promise<any> {
    return new Promise((getJobsResolve, getJobsReject) => {
      this.loaderService.display(true);
      this.externaldatasourceService.executeExternalDataSource(219)
      .then(getJobsResult => {
        getJobsResult.forEach(job => {
          job.jobSteps.sort((a, b) => a.order < b.order ? -1 : 1);
          job.jobSteps.forEach(step => {
            step.parameterValues.sort((a, b) => a.stepTypeParameter.parameterName < b.stepTypeParameter.parameterName ? -1 : 1);
          });

        });
        this.jobs = getJobsResult;
        this.jobsOrig = this.commonService.createCopyOfArray(this.jobs);
        getJobsResolve(null);
        this.loaderService.display(false);
      })
      .catch(error => {
        getJobsReject();
        this.loaderService.display(false);
      });
    });
  }

  saveSchedule(schedule): Promise<any> {
    let jobExternalDataSourceId = 232;
    let param = this.commonService.getModifyArrayBody(schedule, []);

    return new Promise((saveScheduleResolve, saveScheduleReject) => {
      this.externaldatasourceService.executeExternalDataSource(jobExternalDataSourceId, [param])
      .then(saveScheduleResult => { saveScheduleResolve(saveScheduleResult); })
      .catch(error => { saveScheduleReject(error); });
    });
  }

  createSchedule(schedule): Promise<any> {
    let jobExternalDataSourceId = 231;
    let param = this.commonService.getModifyArrayBody(schedule, ['id']);
    return new Promise((createScheduleResolve, createScheduleReject) => {
      this.externaldatasourceService.executeExternalDataSource(jobExternalDataSourceId, [param])
      .then(saveScheduleResult => {
        schedule.id = saveScheduleResult.id;
        createScheduleResolve(saveScheduleResult);
      })
      .catch(error => {
        createScheduleReject(error);
      });
    });
    return new Promise((deleteScheduleResolve, deleteScheduleReject) => {
    });
  }

  deleteSchedule(schedule): Promise<any> {
    const jobExternalDataSourceId = 233;
    return new Promise((deleteScheduleResolve, deleteScheduleReject) => {
      this.externaldatasourceService.executeExternalDataSource(jobExternalDataSourceId, [schedule])
      .then(deleteScheduleResult => { deleteScheduleResolve(deleteScheduleResult); })
      .catch(error => { deleteScheduleReject(error); });
    });
  }

  checkJob(job) {
    const errorObject = {
      errors: [],
      warnings: [], 
      job: job,
      stepsWithMissingParameter: []
    };
    if (job.isActive == false) {
      errorObject.warnings.push({ type: 'JobInactive', job: job });
    }
    console.warn(errorObject)
    // Schedule checks
    const jobSchedule = this.jobSchedules.find(schedule => schedule.jobId == job.id);
    if (!jobSchedule) {
      errorObject.errors.push({ type: 'NoScheduleForJob', displayValue: job.defaultName });
    } else {
      if (jobSchedule.isActive == false) {
        errorObject.warnings.push({ type: 'InactiveSchedule', displayValue: job.defaultName });
      }
    }

    // Step checks
    job.jobSteps.forEach(jobstep => {
      const referenceStep = this.jobStepTypes.find(type => type.id == jobstep.stepTypeId );
      // Active check
      if(jobstep.isActive == false) {
        errorObject.warnings.push({ type: 'InactiveStep', displayValue: jobstep.id + ' ' + jobstep.defaultName, step: jobstep });
      }

      // Invalid parameter values
      jobstep.parameterValues.forEach(parameterValue => {
        const referenceParameter = referenceStep.jobStepTypeParameters.find(
          jobStepTypeParameter => jobStepTypeParameter.id == parameterValue.stepTypeParameterId
        );
        if (referenceParameter) {
          if (referenceParameter.isMandatory && this.commonService.isNullOrUndefined(parameterValue.value)) {
            errorObject.errors.push({ type: 'EmptyMandatoryField', displayValue: parameterValue.stepTypeParameter.parameterName, step: jobstep });
          }
          if (referenceParameter.isMandatory && parameterValue.value == '') {
            errorObject.errors.push({ type: 'EmptyMandatoryField', displayValue: parameterValue.stepTypeParameter.parameterName, step: jobstep });
          } 
        } else {
          errorObject.warnings.push({ type: 'ParameterTypeNotFound', displayValue: parameterValue.stepTypeParameter.parameterName, step: jobstep });
        }
      });

      // Missing parameters in step
      referenceStep.jobStepTypeParameters.forEach(jobStepParameter => {
        const referenceParameter = jobstep.parameterValues.find(parameterValue => parameterValue.stepTypeParameterId == jobStepParameter.id);
        if (this.commonService.isNullOrUndefined(referenceParameter)) {
          if (jobStepParameter.isMandatory) {
            errorObject.errors.push({ type: 'MissingParameterInStep', displayValue: jobStepParameter.parameterName, 
              parameterValue: jobStepParameter, step: jobstep });
          } else {
            errorObject.warnings.push({ type: 'MissingParameterInStep', displayValue: jobStepParameter.parameterName, 
              parameterValue: jobStepParameter, step: jobstep });
          }    
          if (!this.commonService.checkIfItemIsInArray(errorObject.stepsWithMissingParameter, jobstep)) {
            errorObject.stepsWithMissingParameter.push(jobstep);
          }          
        }
      });
    });
    console.log(errorObject);
    this.applicationInfoService.miscSettings['jobCheckResult'] = errorObject;
    this.eventService.showJjPopup(job.defaultName, 'adminjobcheckresult', "750px;700px");
  }



  ////////////////////////////////// JOBS
  updateJob(job): Promise<any> {
    return new Promise((updateJobResolve, updateJobReject) => {
      this.externaldatasourceService.executeExternalDataSource(220, [this.commonService.getModifyArrayBody(job, ['jobSteps'])])
      .then(() => { updateJobResolve(job); })
      .catch(error => { updateJobReject(error); });
    });
  }

  createJob(job): Promise<any> {
    return new Promise((createJobResolve, createJobReject) => {
      this.externaldatasourceService.executeExternalDataSource(221, [this.commonService.getModifyArrayBody(job, ['jobSteps', 'id'])])
      .then(createJobResult => { job.id = createJobResult.id; createJobResolve(job); })
      .catch(error => { createJobReject(error); });
    });
  }

  ////////////////////////////////// STEPS
  updateJobStep(jobStep): Promise<any> {
    return new Promise((updateJobStepResolve, updateJobStepReject) => {
      this.externaldatasourceService.executeExternalDataSource(225, [this.commonService.getModifyArrayBody(jobStep, ['parameterValues', 'stepType'])])
      .then(updateJobStepResult => { jobStep.id = updateJobStepResult.id; updateJobStepResolve(jobStep); })
      .catch(error => { updateJobStepReject(error); });
    });
  }

  createJobStep(jobStep): Promise<any> {
    return new Promise((createJobStepResolve, createJobStepReject) => {
      this.externaldatasourceService.executeExternalDataSource(224, [this.commonService.getModifyArrayBody(jobStep, ['parameterValues', 'stepType', 'id'])])
      .then(saveJobStepResult => { jobStep.id = saveJobStepResult.id; createJobStepResolve(jobStep); })
      .catch(error => { createJobStepReject(error); });
    });
  }

  ////////////////////////////////// PARAMETER
  createJobStepParameter(parameter): Promise<any> {   
    return new Promise((createJobStepParameterResolve, createJobStepParameterReject) => {
      console.warn(parameter);
      this.externaldatasourceService.executeExternalDataSource(227, [this.commonService.getModifyArrayBody(parameter, [])])
      .then(saveJobStepParameterResult => { parameter.id = saveJobStepParameterResult.id; createJobStepParameterResolve(parameter); })
      .catch(error => { createJobStepParameterReject(error); });
    });
  }

  updateJobStepParameter(parameter): Promise<any> {
    return new Promise((updateJobStepParameterResolve, updateJobStepParameterReject) => {
      this.externaldatasourceService.executeExternalDataSource(228, [this.commonService.getModifyArrayBody(parameter, ['stepTypeParameter'])])
      .then(updateJobStepParameterResult => { parameter.id = updateJobStepParameterResult.id; updateJobStepParameterResolve(parameter); })
      .catch(error => { updateJobStepParameterReject(error); });
    });
  }
}
