import { Component, OnInit, OnDestroy } from '@angular/core';
import { WizardService } from '../wizard.service';
import { ExternalDataSource_QueryType, ExternaldatasourceService } from 'app/jollyjupiter/service/externaldatasource.service';
import { LoaderService } from 'app/shared/service/loader-service';
import { ApplicationInfoService } from 'app/core/application/application-info.service';
import { CommonService } from 'app/jollyjupiter/service/common.service';
import { EntityService } from 'app/jollyjupiter/service/entity.service';
import { DatafilterService } from 'app/jollyjupiter/service/datafilter.service';
import { EventService } from 'app/jollyjupiter/service/event.service';
import { Subscription } from 'rxjs';
import { AxivasTranslateService } from 'app/shared/translation/axivas-translate.service';
import { InternalMethodType, MethodService } from 'app/jollyjupiter/service/method.service';
import { MessagingService } from 'app/jollyjupiter/service/messaging.service';

@Component({
  selector: 'app-wizard-microcampaign',
  templateUrl: './wizard-microcampaign.component.html',
  styleUrls: ['./wizard-microcampaign.component.scss']
})
export class WizardMicrocampaignComponent implements OnInit, OnDestroy {
  selectCurrentDataFilterEventSubscription: Subscription = new Subscription();
  customEventSubscription: Subscription = new Subscription();
  campaigns = [];  
  microcampaigns = [];
  entityId = '4';
  users = [];

  // Filter
  dataFilter = null;
  dataFilterCaller = 'microCampaignWizard';
  filterExpanded = false;
  filterSegments = [];
  filterSegmentMemberOperators = null;
  validEntities = null;
  selectedTemplate = null;
  consentString = '';
  mediaString = '';

  constructor(
    public wizardService: WizardService,
    private externaldatasourceService: ExternaldatasourceService,
    public loaderService: LoaderService,
    public applicationInfoService: ApplicationInfoService,
    private commonService: CommonService,
    private entityService: EntityService,
    private datafilterService: DatafilterService,
    private eventService: EventService,
    private axivasTranslateService: AxivasTranslateService,
    private methodService: MethodService,
    private messagingService: MessagingService
  ) { }
  

  ngOnInit(): void {
    this.getCampaigns();
    this.getMicroCampaignStarter();
    this.getUsers();
    this.selectCurrentDataFilterEventSubscription = this.eventService.selectCurrentDataFilterEvent.subscribe(event => {
      if (event.target === this.dataFilterCaller) { 
        this.applyFilter(event.arguments[0].id); 
      }
    });
    this.applicationInfoService.miscSettings['consentWizardArray'] = this.wizardService.wizardArray;    

    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.followUpDate)) { this.wizardService.wizardArray.followUpDate = new Date(Date.now()); }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.totalCount)) { this.wizardService.wizardArray.totalCount = 0; }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.filterId)) { this.wizardService.wizardArray.filterId = null; }
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.projectId)) { this.wizardService.wizardArray.filterId = this.applicationInfoService.projectID; }

    this.customEventSubscription = this.eventService.customEvent.subscribe(event => { 
      if(event.id == 'lastMatTableResultWithoutControlFilters') {
        this.wizardService.wizardArray.totalCount = this.applicationInfoService.miscSettings['lastMatTableResultWithoutControlFiltersCountTotalRecords'];
        this.wizardService.wizardArray.tableLayoutDefinitionId = this.applicationInfoService.miscSettings['lastMatTableResultTableDefinitionId'];
        const consentData = this.applicationInfoService.miscSettings['lastMatTableConsentData'];
        if (!this.commonService.isNullOrUndefined(consentData)) {
          if (consentData.consentTypes.length == 0) {
            this.wizardService.wizardArray.ignoreOptin = true;
            this.wizardService.wizardArray.mediumTypes = [];
            this.wizardService.wizardArray.consentTypes = [];  
          } else {
            this.wizardService.wizardArray.mediumTypes = consentData.mediumTypes;
            this.wizardService.wizardArray.consentTypes = consentData.consentTypes;
            this.wizardService.wizardArray.ignoreOptin = false;  
          }
        } else {
          this.wizardService.wizardArray.ignoreOptin = true;
          this.wizardService.wizardArray.mediumTypes = [];
          this.wizardService.wizardArray.consentTypes = [];
        }
        this.updateMailshotBatch();
      }
    });
    this.getConsenteInfo();
  }

  ngOnDestroy(): void {
    if ( this.customEventSubscription) {  this.customEventSubscription.unsubscribe(); }
    if ( this.selectCurrentDataFilterEventSubscription) {  this.selectCurrentDataFilterEventSubscription.unsubscribe(); }
  }

  applyFilter(filterId, updateMailshotBatch = true) {
    this.datafilterService.getDataFilterInformation(filterId)
    .then(getDataFilterInformationResult => {
      this.dataFilter = filterId;
      this.wizardService.wizardArray.filterId = filterId;
      if (updateMailshotBatch) {
        this.updateMailshotBatch();
      }
      this.datafilterService.getSegmentMemberOperators()
      .then(getFilterSegmentMemberOperatorsResult => {
        this.filterSegmentMemberOperators = getFilterSegmentMemberOperatorsResult;
      });
      this.validEntities = this.datafilterService.getValidEntities(0);
      this.applicationInfoService.myLeadsSelectedFilter = getDataFilterInformationResult;
      this.filterSegments = [];
      if (getDataFilterInformationResult.length > 0) {
        getDataFilterInformationResult.filterSegments.forEach(filterSegment => {
          this.filterSegments.push(filterSegment);
        });
      }
    });
  }

  clearDataFilter() {
    this.dataFilter = null;
    this.wizardService.wizardArray.filterId = null;    
    this.filterExpanded = false;
    this.applicationInfoService.myLeadsSelectedFilter = null;
    this.filterSegments = [];
    this.updateMailshotBatch();
  }

  editDataFilter() {
    const externalDataSource = this.entityService.getExternalDataSourceByType(
      this.applicationInfoService.entities.Item(this.entityId),
        ExternalDataSource_QueryType.MyLeadsQuery + 15);
    if (!this.commonService.isNullOrUndefined(externalDataSource)) {
      this.applicationInfoService.dataFilterPreviewControl = 113088;
      this.applicationInfoService.dataFilterPreviewControQuery = externalDataSource.dataQuery;
    }
    this.datafilterService.setFilterEnvironment(
      this.dataFilter,
      this.entityId,
      this.dataFilterCaller,
      null,
      36
    );
    this.eventService.showJjPopup('DataFilterMainPanel.Label.SectionHeader', 'datafiltermainpanel', '80');
  }

  getCampaigns() {
    this.campaigns = this.applicationInfoService.campaigns.toArray().filter(campaign => campaign.isActive == true);
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.campaignId)) {
      if (this.applicationInfoService.campaingnModeId == null) {
        if (this.campaigns.length > 0) {
          this.wizardService.wizardArray.campaignId = this.campaigns[0].id;
        }
      } else {
        this.wizardService.wizardArray.campaignId = this.applicationInfoService.campaingnModeId;
      }
    }
  }

  updateMailshotBatch(): Promise<any> {
    return new Promise((updateMailshotBatchResolve, updateMailshotBatchReject) => {
      const array = {
        id: this.wizardService.wizardArray.id,
        batchName: this.wizardService.wizardArray.batchName,
        status: this.wizardService.wizardArray.status,
        wizardValues: JSON.stringify(this.wizardService.wizardArray)
      }
      this.externaldatasourceService.executeExternalDataSource(855, [this.commonService.getModifyArrayBody(
        array, [])
      ]).then(() => {
        this.eventService.customEvent.emit({ id: 'updateBookingBatch'} );
        updateMailshotBatchResolve(null);
      });
    });
  }

  getMicroCampaignStarter() {
    this.microcampaigns = [];
    this.applicationInfoService.results.toArray().forEach(result => {
      if (result.typeLookupId == 6216) {
        if (this.wizardService.wizardArray.resultId == null) {
          this.wizardService.wizardArray.resultId = result.id;
        }
        this.microcampaigns.push(result);
      }
    });
  }

  getUsers() {
    this.users = this.applicationInfoService.projectUsers.toArray();
    if (this.commonService.isNullOrUndefined(this.wizardService.wizardArray.followUpUserId)) {
      this.wizardService.wizardArray.followUpUserId = this.applicationInfoService.userID;
    }
  }

  startMicroCampaignBatch() {
    const resultInfo = this.applicationInfoService.results.toArray().find(result => result.id == this.wizardService.wizardArray.resultId);
    if (resultInfo) {
      this.wizardService.wizardArray.stepId = resultInfo.stepId;
      this.updateMailshotBatch().then(() => {
        let microCampaignBatchText = this.axivasTranslateService.getTranslationTextForToken('Task.Message.MailCampaignBatch');
        microCampaignBatchText = microCampaignBatchText.replace('<0>', this.axivasTranslateService.getTranslationTextForObject(
          resultInfo, 'nameTranslationToken') );
        microCampaignBatchText = microCampaignBatchText.replace('<1>', this.wizardService.wizardArray.totalCount);
        this.methodService.launchInternalMethod(InternalMethodType.AskYesNo, ['MicroCampaign', microCampaignBatchText]).then(() => {            
          this.loaderService.display(true);         
          this.prepareEntityTableDataForBookingBatch().then(() => {
            this.externaldatasourceService.executeExternalDataSource(851, [this.wizardService.wizardArray.id]).then(() => {
              this.messagingService.showDefaultSuccess(
                'MicroCampaignBatchStarter.Message.CampaignStartedHeader', 
                'MicroCampaignBatchStarter.Message.CampaignStarted'
              )
              this.loaderService.display(false);    
            }).catch(() => { this.loaderService.display(false); });
          }).catch(() => { this.loaderService.display(false); });
        });    
      });
    }
  }

  prepareEntityTableDataForBookingBatch(): Promise<any> {
    return new Promise((prepareEntityTableDataForBookingBatchResolve, prepareEntityTableDataForBookingBatchReject) => {    
      this.externaldatasourceService.executeExternalDataSource(850, [
        this.wizardService.wizardArray.tableLayoutDefinitionId,
        this.wizardService.wizardArray.filterId,
        [],
        this.wizardService.wizardArray.id,
        this.wizardService.wizardArray.consentTypes,
        this.wizardService.wizardArray.mediumTypes,
        this.wizardService.wizardArray.lastXDays
      ]).then(prepareEntityTableDataForBookingBatchResult => {
        prepareEntityTableDataForBookingBatchResolve(prepareEntityTableDataForBookingBatchResult);
      }).catch(error => {
        prepareEntityTableDataForBookingBatchReject(error);
      });      
    });
  }

  getConsenteInfo() {
    this.consentString = '';
    this.mediaString = '';

    const mediumTable = this.applicationInfoService.projectLookupTables.find( projectLookupTable => projectLookupTable.definitionId == Number(16) && 
      projectLookupTable.source == 'central');
    const consentTable = this.applicationInfoService.projectLookupTables.find( projectLookupTable => projectLookupTable.definitionId == Number(17) && 
      projectLookupTable.source == 'central');

    this.wizardService.wizardArray.consentTypes.forEach(consentType => {
      const consentItem = consentTable.lookups.find(tableitem => tableitem.id == consentType);
      if (consentItem) {
        if (this.consentString != '') { this.consentString = this.consentString.concat(', '); }
        this.consentString = this.consentString.concat(consentItem.defaultName);
      }      
    });
    this.wizardService.wizardArray.mediumTypes.forEach(mediumType => {
      const mediumItem = mediumTable.lookups.find(tableitem => tableitem.id == mediumType);
      if (mediumItem) {
        if (this.mediaString != '') { this.mediaString = this.mediaString.concat(', '); }
        this.mediaString = this.mediaString.concat(mediumItem.defaultName);
      }      
    });    
  }
}
